import { PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import params from '~/params/variant.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useChannel from '~/utils/useChannel';
import useEspace from '~/utils/useEspace';

const AdminVariantUpdate: FC<PageProps & UserProps> = function ({
  location,
  params: { channelId, id: espaceId, variantId },
}) {
  const [espace] = useEspace({ id: espaceId, pathname: location.pathname });
  const [channel, items] = useChannel({
    channelId,
    espaceId,
    admin: true,
    pathname: location.pathname,
  });

  const model = new GenericData({
    espaceId,
    params,
    collectionName: 'variants',
    orderDirection: 'desc',
    orderField: 'label',
    parentCollectionName: 'channels',
    parentId: channelId,
  });

  return (
    <TemplateAdmin>
      <CMSView>
        <Form
          displayAdd={false}
          displayArchive={false}
          displayNavigation={false}
          displayRemove={false}
          docId={variantId}
          itemPathnamePrefix={`/admin/espaces/${espaceId}/channels/${channelId}/variants/`}
          model={model}
          name="variant"
          params={{ channelId, espaceId, variantId }}
          subMenu={items}
          title={`${espace?.label} ⇾ ${channel?.label}`}
          type="update"
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(AdminVariantUpdate, 'admin');
